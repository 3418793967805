<template>
    <div class="flex justify-center items-center gap-3 mt-4">
      <button 
        @click="$emit('prev')" 
        :disabled="currentPage === 1" 
        class="pagination-btn">
        &lt;
      </button>
  
      <span class="pagination-text">
        Página {{ currentPage }} de {{ totalPages }}
      </span>
  
      <button 
        @click="$emit('next')" 
        :disabled="currentPage === totalPages" 
        class="pagination-btn">
        &gt;
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      currentPage: {
        type: Number,
        required: true
      },
      totalPages: {
        type: Number,
        required: true
      }
    }
  };
  </script>
  
  <style scoped>
  .pagination-btn {
    padding: 10px 16px;
    background-color: #e0e0e0;
    color: #333;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  .pagination-btn:hover {
    background-color: #d0d0d0;
  }
  .pagination-btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    background-color: #f0f0f0;
  }
  .pagination-text {
    font-size: 1.125rem;
    color: #333;
  }
  </style>
  