<template>
  <div class="recorded-classes-container">
    <h1>Clases Grabadas</h1>
    <button @click="$router.go(-1)" class="back-button">← Volver atrás</button>

    <!-- Lista de Categorías -->
    <div class="category-list">
      <h2>Selecciona una categoría</h2>


    <!-- Reproductor de video -->
    <div v-if="videoUrl" class="video-player">
      <video 
        id="videoPlayer" 
        class="video-js vjs-default-skin" 
        controls 
        controlsList="nodownload" 
        @contextmenu.prevent
      >
        <source :src="videoUrl" type="video/mp4" />
        Tu navegador no soporta la etiqueta de video.
      </video>
    </div>

     

     <!-- Reproductor de video personalizado 
     <SecureVideoPlayer v-if="videoUrl" :videoPath="videoUrl" />
    -->

      <ul>
        <li 
          v-for="(videos, category) in categories" 
          :key="category" 
          @click="selectCategory(category)"
        >
          <span>{{ category }}</span>
        </li>
      </ul>
    </div>

    <!-- Lista de Clases en la Categoría Seleccionada -->
    <div v-if="selectedCategory" class="video-list">
      <h2>{{ selectedCategory }}</h2>
      <ul>
        <li 
          v-for="video in categories[selectedCategory]" 
          :key="video.class_id" 
          @click="loadVideo(video.video_path)"
        >
          <span>{{ video.title }}</span>
        </li>
      </ul>
    </div>

   
    <!-- Mensaje de error -->
    <div v-if="errorMessage" class="error-message">
      <p>{{ errorMessage }}</p>
    </div>
  </div>


     <!-- Pie de página --> 
         
     <footer class="footer">
        <p>Fortunia 2024 Todos los Derechos Reservados</p>
        <p>Creado por <strong>Coss Consulting Group</strong></p>
        <nav>
          <ul>
            <li><router-link to="/privacy">Política de Privacidad</router-link></li>
            <li><router-link to="/disclaimer">Disclaimers</router-link></li>
            <li><router-link to="/about">Acerca de</router-link></li>
            <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
    
          </ul>
        </nav>
      </footer>
    
</template>

<script>
import axios from 'axios';
 //import SecureVideoPlayer from '@/components/SecureVideoPlayer.vue';


export default {
 /* components: {
    SecureVideoPlayer
  },
  */
  
  data() {
    return {
      categories: {}, // Videos organizados por categoría
      selectedCategory: null, // Categoría seleccionada
      videos: [], // Lista de videos
      videoUrl: null, // URL firmada del video seleccionado
      errorMessage: '', // Mensaje de error
      tokenStatusInterval: null, // Intervalo para verificar el estado del token
    };
  },

  async mounted() {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        this.errorMessage = '❌ No se encontró el token de autenticación.';
        this.logout();
        return (window.location.href = "/login");
      }

      const response = await axios.get('https://api.fortunia.org/api/wasabi/classes', {
        headers: { Authorization: `Bearer ${token}` }
      });

       this.categories = response.data.categories || {};
    } catch (error) {
      this.errorMessage = '❌ Error al cargar las clases.';
      console.error(error);
    }

    // Llamar a verifyTokenStatus() cada 5 segundos
    this.tokenStatusInterval = setInterval(this.verifyTokenStatus, 5000);
  },

  beforeUnmount()  {
    // Limpiar el intervalo cuando el componente se destruya
    if (this.tokenStatusInterval) {
      clearInterval(this.tokenStatusInterval);
    }
  },

  methods: {

    selectCategory(category) {
      this.selectedCategory = category;
    },

    async verifyTokenStatus() {
      const token = localStorage.getItem("token");

      if (!token) {
        console.error("No se encontró el token de sesión.");
        this.logout();
        return (window.location.href = "/login");
      }

      // Validar el token antes de hacer la solicitud
      try {
        const decoded = JSON.parse(atob(token.split(".")[1])); // Decodificar payload del JWT
        const exp = decoded.exp * 1000; // Convertir a milisegundos

        if (Date.now() >= exp) {
          console.error("El token ha expirado. Cerrando sesión.");
          this.logout();
          return (window.location.href = "/login");
        }
      } catch (error) {
        console.error("Error al decodificar el token. Cerrando sesión.");
        this.logout();
        return (window.location.href = "/login");
      }

      // Si el token es válido, proceder con la verificación en el backend
      try {
        const response = await axios.get("https://api.fortunia.org/api/users/verify-token-status", {
          headers: { Authorization: `Bearer ${token}` },
        });

        console.log("Respuesta de verificación de token:", response.data);

        if (!response.data.isValid) {
          console.error("Token inválido. Cerrando sesión.");
          this.logout();
          window.location.href = "/login";
        } else {
          console.log("Token válido.");
        }
      } catch (error) {
        console.error("Error de red al verificar el token:", error.message);
        this.logout();
      }
    },

    logout() {
    axios
      .post("https://api.fortunia.org/api/auth/logout")
      .then(() => {
        // Limpia el localStorage
        localStorage.removeItem("token");
        localStorage.removeItem("role");
  
        // Redirige al usuario a la página de inicio
        window.location.href = "/";
      })
      .catch((error) => {
        console.error("Error al cerrar sesión:", error);
  
        // Asegúrate de limpiar y redirigir incluso si hay un error
        localStorage.removeItem("token");
        localStorage.removeItem("role");
        window.location.href = "/";
      });
  },

     async loadVideo(videoPath) {
      try {
        this.errorMessage = ''; 
        this.videoUrl = null;

        const response = await axios.post('https://api.fortunia.org/api/wasabi/get-signed-url', 
          { videoPath }, 
          { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
        );

        if (response.data && response.data.signedUrl) {
          this.videoUrl = response.data.signedUrl;
        } else {
          this.errorMessage = '❌ No se pudo obtener la URL firmada.';
        }
      } catch (error) {
        this.errorMessage = '❌ No se pudo cargar el video seleccionado.';
        console.error(error);
      }
    }
  }
};
</script>

<style scoped>
/* Estilos para la página de clases grabadas */
.recorded-classes-container {
  text-align: center;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

h1 {
  font-size: 2.5rem;
  color: #333;
}

.video-list {
  margin-bottom: 30px;
}

.video-list h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #444;
}

.video-list ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.video-list li {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 8px;
  width: 250px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.video-list li:hover {
  background-color: #f0f0f0;
  transform: translateY(-5px);
}

.video-player {
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  text-align: center;
}

.video-player video {
  max-width: 100%;
  height: auto;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 20px;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .video-list li {
    width: 100%;
    max-width: 300px;
  }

  .video-player {
    max-width: 100%;
  }

  h1 {
    font-size: 2rem;
  }
}



  
  /* Pie de Página */
  .footer {
    margin-top: 20px;
    padding: 15px;
    background-color: var(--secondary-color);
    text-align: center;
    border-top: 1px solid #ddd;
    font-size: 14px;
    color: #030303;
  }
  
  .footer p {
    margin: 5px 0;
  }
  
  .footer nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 10px;
    flex-wrap: wrap;
  }
  
  .footer nav ul li {
    margin: 5px 0;
  }
  
  .footer nav ul li a {
    text-decoration: none;
    color: var(--primary-color);
    font-weight: bold;
    transition: color 0.3s ease;
    font-size: 14px;
  }
  
  .footer nav ul li a:hover {
    color: #000;
  }
  
  /* Estilos específicos para pantallas pequeñas */
  @media (max-width: 768px) {
    .footer {
      font-size: 12px;
      padding: 10px;
    }
  
    .footer nav ul {
      flex-direction: column;
      gap: 10px;
    }
  
    .footer nav ul li a {
      font-size: 12px;
    }
  }
  
  @media (max-width: 480px) {
    .footer {
      font-size: 10px;
      padding: 8px;
    }
  
    .footer nav ul {
      flex-direction: column;
      gap: 5px;
    }
  
    .footer nav ul li a {
      font-size: 10px;
    }
  }
  
  /* Estilo adicional para mejorar la visualización */
  .footer p strong {
    font-weight: bold;
  }
  
  

  .back-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
  margin-bottom: 15px;
}

.back-button:hover {
  background-color: #0056b3;
}

 /* Categorias de los Videos */

  
.category-list ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.category-list li {
  cursor: pointer;
  padding: 15px;
  border: 1px solid #ddd;
  margin: 10px;
  border-radius: 8px;
  width: 200px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s, transform 0.3s;
}

.category-list li:hover {
  background-color: #f0f0f0;
  transform: translateY(-5px);
}



</style>
