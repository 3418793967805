import { createRouter, createWebHistory } from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import AdminPanel from '../views/AdminPanel.vue';
import HomePage from '../views/HomePage.vue';
import OperationsPage from '../views/OperationsPage.vue';
import EditOperationPage from '../views/EditOperationPage.vue'; 
import UserManagement from '@/components/UserManagement.vue';
import ForgotPassword from '@/views/ForgotPassword.vue';
import VerifyCodePage from '../views/VerifyCodePage.vue';
import PrivacyPolicy from '../views/PrivacyPolicy.vue';
import DisclaimerPage from '../views/DisclaimerPage.vue';
import AboutPage from '../views/AboutPage.vue';
import LiveTransmissions from '../views/LiveTransmissions.vue';
import RecordedClassesPage from '../views/RecordedClassesPage.vue'; 
import LibraryBooks from '../views/LibraryBooks.vue';
import ManageBooks from '../views/ManageBooks.vue';
import ManageClases from '@/views/ManageClases.vue';

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel,
    meta: { requiresAuth: true, isAdmin: true }
  },
  {
    path: '/admin/create',
    name: 'CreateUser',
    component: UserManagement,
    props: { form: 'create' }
  },
  {
    path: '/admin/delete',
    name: 'DeleteUser',
    component: UserManagement,
    props: { form: 'delete' }
  },
  {
    path: '/admin/change-password',
    name: 'ChangePassword',
    component: UserManagement,
    props: { form: 'changePassword' }
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage
  },
  {
    path: '/operations',
    name: 'operations',
    component: OperationsPage,
    meta: { requiresAuth: true }
  },
  // Nueva ruta para la edición de operaciones
  {
    path: '/edit-order/:id', 
    name: 'EditOrder',
    component: EditOperationPage,
    meta: { requiresAuth: true, isAdmin: true }, // Asegurarse de que solo los administradores puedan acceder
    props: true // Pasar el parámetro `id` como prop al componente
  },
  {
    path: "/verify",
    name: "VerifyCodePage",
    component: VerifyCodePage,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: '/privacy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/disclaimer',
    name: 'DisclaimerPage',
    component: DisclaimerPage
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: AboutPage
  },
  {
    path: "/transmissions",
    name: "LiveTransmissions",
    component: LiveTransmissions
  },
  {
    path: '/library',  
    name: 'RecordedClasses',
    component: RecordedClassesPage
  },
  {
    path: '/mindfulness',  
    name: 'LibraryBooks',
    component: LibraryBooks
  },
  {
    path: '/managebooks',  
    name: 'ManageBooks',
    component: ManageBooks
  },
  {
    path: '/manageclases',  
    name: 'ManageClases',
    component: ManageClases
  }


  
 

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem('token');
  const userRole = localStorage.getItem('role');

  if (to.meta.requiresAuth && !isAuthenticated) {
    next('/');
  } else if (to.meta.isAdmin && userRole !== 'admin') {
    next('/');
  } else {
    next();
  }
});

export default router;
