<template>
    <div class="p-6 bg-gray-100 dark:bg-gray-900 min-h-screen">
      <div class="flex justify-between items-center mb-6">
        <h1 class="text-3xl font-bold text-gray-800 dark:text-gray-200">Gestión de Clases</h1>
        <button @click="$router.go(-1)" class="back-button">⬅ Volver</button>
      </div>


      <!-- Tu lista de clases u otros componentes -->
    <PaginationClass 
      :currentPage="page" 
      :totalPages="totalPages" 
      @prev="handlePrevPage" 
      @next="handleNextPage" 
    />
  
      <div class="flex flex-col md:flex-row md:items-center gap-4 mb-6">
        <input v-model="search" type="text" placeholder="Buscar clases..." 
          class="input-field" />
        <VueDatePicker v-model="selectedDate" @update:modelValue="fetchClasses" 
          class="input-field" />
      </div>
  
      <button @click="openForm()" class="primary-button">+ Nueva Clase</button>
  
      <div class="overflow-x-auto mt-6">
        <table class="class-table">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Título</th>
              <th>Nivel</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
  <tr v-for="clase in filteredClasses" :key="clase.id">
    <td>{{ clase.class_date }}</td>
    <td>{{ clase.class_time }}</td>
    <td>{{ clase.title }}</td>
    <td>{{ clase.level }}</td>
    <td class="actions">
      <button @click="console.log('Editar:', clase)">✏ Editar</button>
      <button @click="console.log('Eliminar:', clase.id)">🗑 Eliminar</button>
    </td>
  </tr>
</tbody>

        </table>
      </div>
  
      <Pagination :total="total" :currentPage="page" @page-changed="fetchClasses" />
  
      <ClassForm v-if="showForm" :ClassData="selectedClass" @close="closeForm" @saved="fetchClasses" />
  
      <footer class="footer">
        <p>Fortunia 2024 &copy; Todos los Derechos Reservados</p>
        <p>Creado por <strong>Coss Consulting Group</strong></p>
        <nav>
          <ul>
            <li><router-link to="/privacy">Política de Privacidad</router-link></li>
            <li><router-link to="/disclaimer">Disclaimers</router-link></li>
            <li><router-link to="/about">Acerca de</router-link></li>
            <li><a href="https://blog.fortunia.org" target="_blank">Blog</a></li>
          </ul>
        </nav>
      </footer>
    </div>
  </template>
  
  <script>
  import ClassForm from '@/components/ClassForm.vue';
  import Pagination from '@/components/PaginationClass.vue'; 
  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import axios from 'axios';
  
  export default {
    components: { ClassForm, Pagination, VueDatePicker },
    data() {
      return {
        classes: [],         // Asegúrate de que siempre sea un array vacío inicialmente
        search: '',
        selectedDate: null,
        showForm: false,
        selectedClass: null,
        totalPages: 1,
        page: 1,
        total: 0
      };
    },
    computed: {
        filteredClasses() {
    console.log("Filtrando clases. Valor actual de this.classes:", this.classes);
    return this.classes.length > 0 ? this.classes : [];
  }


    },
    methods: {
      getAuthHeaders() {
        return { Authorization: `Bearer ${localStorage.getItem("token")}` };
      },
     
      async fetchClasses(page = 1) {
  try {
    const { data } = await axios.get(`https://api.fortunia.org/api/manageclases/classes?page=${page}`, {
      headers: this.getAuthHeaders()
    });

    console.log("Respuesta completa de la API:", data);

    if (data && Array.isArray(data.classes)) {
      this.classes = data.classes;
    } else {
      console.error("Estructura inesperada en la respuesta:", data);
      this.classes = [];
    }

    this.totalPages = Math.ceil(data.total / data.perPage);
  } catch (error) {
    console.error("Error al obtener clases:", error);
  }
},




      handlePrevPage() {
      if (this.page > 1) {
        this.page--;
        this.fetchClasses(this.page);
      }
    },
    handleNextPage() {
      if (this.page < this.totalPages) {
        this.page++;
        this.fetchClasses(this.page);
      }
    
  },
      openForm(clase = null) {
        this.selectedClass = clase;
        this.showForm = true;
      },
      closeForm() {
        this.showForm = false;
        this.selectedClass = null;
      },
      async deleteClass(id) {
        if (confirm('¿Estás seguro de que deseas eliminar esta clase?')) {
          try {
            await axios.delete(`https://api.fortunia.org/api/manageclases/classes/${id}`, {
              headers: this.getAuthHeaders()
            });
            this.fetchClasses();  // Vuelve a obtener las clases después de eliminar una
          } catch (error) {
            console.error('Error al eliminar clase:', error);
          }
        }
      }
    },
    mounted() {
        console.log("Montando componente y obteniendo clases...");
        this.fetchClasses(this.page);
    }
  };
</script>

  
  <style scoped>
.back-button {
  background-color: #4CAF50;
  color: white;
  font-size: 1rem;
  padding: 10px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}
.back-button:hover {
  background-color: #45a049;
  transform: scale(1.05);
}

.input-field {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  transition: border 0.3s;
}
.input-field:focus {
  border-color: #2563EB;
  outline: none;
}

.primary-button {
  background-color: #2563EB;
  color: white;
  padding: 12px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}
.primary-button:hover {
  background-color: #1E3A8A;
}

.class-table {
  width: 100%;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.class-table th {
  background-color: #1E40AF;
  color: white;
  padding: 12px;
}
.class-table td {
  padding: 12px;
  text-align: left;
}
.actions button {
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
}
.edit-button {
  background-color: #FBBF24;
  color: white;
}
.edit-button:hover {
  background-color: #D97706;
}
.delete-button {
  background-color: #EF4444;
  color: white;
}
.delete-button:hover {
  background-color: #B91C1C;
}

.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: #374151;
  text-align: center;
  color: white;
  font-size: 14px;
}
.footer ul {
  display: flex;
  justify-content: center;
  gap: 15px;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}
.footer ul li a {
  color: #93C5FD;
  text-decoration: none;
  transition: color 0.3s;
}
.footer ul li a:hover {
  color: #BFDBFE;
}

@media (max-width: 768px) {
  .footer ul {
    flex-direction: column;
    gap: 10px;
  }
}
</style>
