<template>
  <div class="user-management">
    <h2>Gestión de Usuarios</h2>

    <!-- Menú de administración -->
    <div class="admin-menu">
      <button @click="setActiveForm('create')">Crear Usuario</button>
      <button @click="setActiveForm('delete')">Eliminar Usuario</button>
      <button @click="setActiveForm('changePassword')">Cambiar Contraseña</button>
      <button @click="setActiveForm('view')">Ver Usuarios</button>
      <button @click="setActiveForm('edit')">Modificar Usuario</button>
      <button @click="setActiveForm('resendVerification')">Reenviar Correo de Verificación</button>
      <button @click="setActiveForm('manageSubscription')">Gestionar Suscripción Premium</button>
      <button @click="setActiveForm('onlineUsers')">Usuarios en Línea</button> <!-- Nueva opción -->

  

    </div>


<!-- Usuarios en Línea -->
<div v-if="activeForm === 'onlineUsers'" class="online-users-container">
    <h3 class="section-title">Usuarios en Línea</h3>
    <ul class="user-list">
      <li v-for="user in onlineUsers" :key="user.user_id" class="user-item">
        <div class="user-info">
          <span class="user-username">{{ user.username }}</span>
          <span class="last-active">Última actividad: {{ new Date(user.last_active).toLocaleString() }}</span>
        </div>
      </li>
    </ul>
  </div>


 <!-- Formulario de Gestión de Suscripción Premium -->
 <div v-if="activeForm === 'manageSubscription'">
      <h3>Gestionar Suscripción Premium</h3>
      <form @submit.prevent="manageSubscription">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="subscriptionData.userId" type="number" id="userId" required />
        </div>
        <div>
          <label for="subscriptionDuration">Duración de la Suscripción (días):</label>
          <select v-model="subscriptionData.duration" id="subscriptionDuration" required>
            <option value="7">7 días</option>
            <option value="15">15 días</option>
            <option value="30">30 días</option>
            <option value="365">1 Año</option>
          </select>
        </div>
        <div>
          <label for="activateSubscription">Activar Suscripción:</label>
          <input v-model="subscriptionData.isActive" type="checkbox" id="activateSubscription" />
        </div>
        <button type="submit">Actualizar Suscripción</button>
      </form>
      <hr />
      <form @submit.prevent="removeSubscription">
        <div>
          <label for="removeUserId">ID del Usuario para Eliminar Suscripción:</label>
          <input v-model="removeSubscriptionId" type="number" id="removeUserId" required />
        </div>
        <button type="submit">Eliminar Suscripción</button>
      </form>
    </div>    

<!-- Crear Usuario -->
<div v-if="activeForm === 'create'">
  <h3>Crear Nuevo Usuario</h3>
  <form @submit.prevent="createUser">
    <div>
      <label for="name">Nombre:</label>
      <input v-model="newUser.name" type="text" id="name" required />
    </div>
    <div>
      <label for="email">Correo Electrónico:</label>
      <input v-model="newUser.email" type="email" id="email" required />
    </div>
    <div>
      <label for="password">Contraseña:</label>
      <input v-model="newUser.password" type="password" id="password" required />
    </div>
    <div>
      <label for="role">Rol:</label>
      <select v-model="newUser.role" id="role" required>
        <option value="user">Usuario</option>
        <option value="admin">Administrador</option>
      </select>
    </div>
    <div>
      <label for="phone_number">Número de Teléfono (Opcional):</label>
      <input v-model="newUser.phone_number" type="text" id="phone_number" />
    </div>
    <div>
      <label for="receive_sms_alerts">
        ¿Recibir Notificaciones por SMS? (Opcional):
      </label>
      <input v-model="newUser.receive_sms_alerts" type="checkbox" id="receive_sms_alerts" />
    </div>
    <button type="submit" :disabled="isSubmitting">Crear Usuario</button>
  </form>
</div>


    <!-- Eliminar Usuario -->
    <div v-if="activeForm === 'delete'">
      <h3>Eliminar Usuario</h3>
      <form @submit.prevent="deleteUser">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="deleteUserId" type="number" id="userId" required />
        </div>
        <button type="submit">Eliminar Usuario</button>
      </form>
    </div>

    <!-- Cambiar Contraseña -->
    <div v-if="activeForm === 'changePassword'">
      <h3>Cambiar Contraseña</h3>
      <form @submit.prevent="changePassword">
        <div>
          <label for="changePasswordId">ID del Usuario:</label>
          <input v-model="changePasswordId" type="number" id="changePasswordId" required />
        </div>
        <div>
          <label for="newPassword">Nueva Contraseña:</label>
          <input v-model="newPassword" type="password" id="newPassword" required />
        </div>
        <button type="submit">Cambiar Contraseña</button>
      </form>
    </div>


     <!-- Ver Usuarios -->
     <div v-if="activeForm === 'view'">
      <h3>Lista de Usuarios</h3>

  <!-- Filtros -->
  <div class="filters">
    <label>
      <input type="checkbox" v-model="filters.showInactive" /> Mostrar Inactivos
    </label>
    <label>
      <input type="checkbox" v-model="filters.showPremium" /> Mostrar Premium
    </label>
    <label>
      <input type="checkbox" v-model="filters.showSmsNotifications" /> Mostrar Notificaciones SMS
    </label>
    <label>
      <input type="checkbox" v-model="filters.emailOnlyNotifications" /> Solo Notificaciones por Email
    </label>


  <!-- Filtro por Nivel de Usuario -->
  <label>
    Nivel de Usuario:
    <select v-model="filters.userLevel">
      <option value="">Todos</option>
      <option value="admin">Administrador</option>
      <option value="Básico">Básico</option>
      <option value="Intermedio">Intermedio</option>
      <option value="Avanzado">Avanzado</option>
    </select>
  </label>

  </div>


      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Recibe SMS</th>
            <th>Estado</th>
            <th>Notificaciones por Email</th>
            <th>Nivel</th>
            <th>Premium</th> 
            <th>Expiración Premium</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="user in filteredUsers" :key="user.id">
            <td>{{ user.id }}</td>
            <td>{{ user.name }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.phone_number || 'N/A' }}</td>
            <td>{{ user.receive_sms_alerts ? 'Sí' : 'No' }}</td>
            <td>{{ user.is_verified ? 'Activo' : 'Inactivo' }}</td>
            <td>{{ user.email_only_notifications ? 'Sí' : 'No' }}</td>
            <td>{{ user.level }}</td>         
            <td>{{ user.is_premium ? 'Sí' : 'No' }}</td>
            <td>{{ user.premium_expiration_date ? new Date(user.premium_expiration_date).toLocaleDateString() : 'N/A' }}</td>
    
            <td>
              <button @click="editUser(user.id)">Editar</button>
              <button @click="toggleUserStatus(user.id)">{{ user.is_verified ? 'Desactivar' : 'Activar' }}</button>
              <button @click="openSubscriptionModal(user)">Gestionar Suscripción</button>
              <button @click="openSmsModal(user)">Enviar SMS</button>
              <button @click="openPushNotificationModal(user)">Enviar Notificación</button>
         
            </td>
          </tr>
        </tbody>
      </table>
    </div>


     <!-- Modificar Usuario -->
     <div v-if="activeForm === 'edit'">
      <h3>Modificar Usuario</h3>
      <form @submit.prevent="updateUser">
        <div>
          <label for="userId">ID del Usuario:</label>
          <input v-model="selectedUser.id" type="number" id="userId" required />
        </div>
        <div>
          <label for="editName">Nombre:</label>
          <input v-model="selectedUser.name" type="text" id="editName" required />
        </div>
        <div>
          <label for="editEmail">Correo Electrónico:</label>
          <input v-model="selectedUser.email" type="email" id="editEmail" required />
        </div>
        <div>
          <label for="editPhone">Teléfono:</label>
          <input v-model="selectedUser.phone_number" type="text" id="editPhone" />
        </div>
        <div>
          <label for="editReceiveSms">Recibe Notificaciones por SMS:</label>
          <input v-model="selectedUser.receive_sms_alerts" type="checkbox" id="editReceiveSms" />
       </div>

       <div>
          <label for="editEmailOnlyNotifications">Recibe solo notificaciones por email:</label>
          <input v-model="selectedUser.email_only_notifications" type="checkbox" id="editEmailOnlyNotifications" />
       </div>

        <div>
          <label for="editStatus">Estado:</label>
          <select v-model="selectedUser.is_verified" id="editStatus">
            <option value="1">Activo</option>
            <option value="0">Inactivo</option>
          </select>
       
        </div>


        <div>
          <label for="editLevel">Nivel:</label>
          <select v-model="selectedUser.level" id="editLevel" required>
            <option value="Básico">Básico</option>
            <option value="Intermedio">Intermedio</option>
            <option value="Avanzado">Avanzado</option>
          </select>
        </div>


        <div>
           <label for="editPassword">Contraseña (dejar en blanco si no se desea cambiar):</label>
          <input v-model="selectedUser.password" type="password" id="editPassword" />
        </div>


        <button type="submit" :disabled="isSubmitting">Guardar Cambios</button>
      </form>
    </div>


      <!-- Reenviar Correo de Verificación -->
      <div v-if="activeForm === 'resendVerification'">
      <h3>Reenviar Correo de Verificación</h3>
      <form @submit.prevent="resendVerificationEmail">
        <div>
          <label for="userVerificationId">ID del Usuario:</label>
          <input v-model="verificationUserId" type="number" id="userVerificationId" required />
        </div>
        <button type="submit" :disabled="isSubmitting">Reenviar Correo de Verificación</button>
      </form>
    </div>


    <!-- Ventana Modal para enviar SMS-->

    <div v-if="showSmsModal" class="modal">
      <div class="modal-content">
        <h3>Enviar SMS a {{ selectedUser.name }}</h3>
        <textarea v-model="smsMessage" placeholder="Escribe tu mensaje"></textarea>
        <div class="modal-actions">
          <button @click="sendSms">Enviar</button>
          <button @click="closeSmsModal">Cancelar</button>
        </div>
      </div>
    </div>


 <!-- Ventana Modal para Enviar Notificación Push -->
<div v-if="showPushModal" class="modal">
  <div class="modal-content">
    <h3>Enviar Notificación a {{ selectedPushUser.name }}</h3>
    <input v-model="pushTitle" type="text" placeholder="Título de la notificación" />
    <textarea v-model="pushMessage" placeholder="Escribe tu mensaje"></textarea>
    <div class="modal-actions">
      <button @click="sendPushNotification">Enviar</button>
      <button @click="closePushModal">Cancelar</button>
    </div>
  </div>
</div>






 <!-- Ventana Modal para gestionar Suscripciones Premium -->
 <div v-if="showModal" class="modal-overlay" @click.self="showModal = false">
    <div class="modal">
      <h3>Gestionar Suscripción Premium para {{ subscriptionData.name }} </h3>
      <form @submit.prevent="manageSubscription">
        <div class="form-group">
          <label for="userId">ID del Usuario:</label>
          <input v-model="subscriptionData.userId" type="number" id="userId" readonly />
        </div>
        <div class="form-group" v-if="!subscriptionData.isActive">
          <label for="subscriptionDuration">Duración de la Suscripción (días):</label>
          <select v-model="subscriptionData.duration" id="subscriptionDuration" required>
            <option value="7">7 días</option>
            <option value="15">15 días</option>
            <option value="30">30 días</option>
            <option value="365">1 Año</option>
          </select>
        </div>
        <!-- Solo mostrar el checkbox si la suscripción está activa -->
        <div class="form-group toggle" v-if="subscriptionData.isActive">
          <label for="activateSubscription">Activar Suscripción:</label>
          <input v-model="subscriptionData.isActive" type="checkbox" id="activateSubscription" />
        </div>
        <div class="button-group">
          <button type="submit" class="btn primary">
            {{ subscriptionData.isActive ? 'Actualizar Suscripción' : 'Asignar Suscripción' }}
          </button>
          <button v-if="subscriptionData.isActive" type="button" @click="removeSubscription" class="btn danger">
            Eliminar Suscripción
          </button>
          <button type="button" @click="showModal = false" class="btn secondary">Cancelar</button>
        </div>
      </form>
    </div>
  </div>




  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {

      //Ventana Modal para las suscripciones

      showModal: false,
      selectedPremiumUser: null,
      subscriptionDuration: 30,

      //Cierre de las Suscripciones en Ventana Modal


      showPushModal: false,
      selectedPushUser: null,
      pushTitle: "",
      pushMessage: "",

      onlineUsersCheckInterval: null,
      onlineUsers: [],
      activeForm: null, // Controla qué formulario se muestra
      users: [],
      showSmsModal: false,
      smsMessage: '',
      newUser: {
        name: '',
        email: '',
        password: '',
        role: 'user',
        phone_number: '', // Nuevo campo
        receive_sms_alerts: false, // Nuevo campo
      },
      subscriptionData: {
        userId: null,
        name: '',
        duration: 7,
        isActive: false,
      },
      selectedUser: {
        id: null,
        name: '',
        email: '',
        phone_number: '',
        receive_sms_alerts: false,
        is_verified: true,
        is_premium: false, 
      },
      filters: {
        showInactive: false,
        showPremium: false,
        emailOnlyNotifications: false,
        showSmsNotifications: false,
        userLevel: '',
      },
      deleteUserId: null,
      changePasswordId: null,
      newPassword: '',
      verificationUserId: null,
      premiumDuration: 7,
      isSubmitting: false,
      removeSubscriptionId: null,
    };
  },


  computed: {
    filteredUsers() {
      return this.users.filter((user) => {
        // Filtros condicionales
        const matchesInactive = this.filters.showInactive ? !user.is_verified : true;
        const matchesPremium = this.filters.showPremium ? user.is_premium : true;
        const matchesSms = this.filters.showSmsNotifications ? user.receive_sms_alerts : true;
        const matchesEmailOnlyNotifications = this.filters.emailOnlyNotifications ? user.email_only_notifications : true;
    
        
        const matchesUserLevel = this.filters.userLevel ? user.level === this.filters.userLevel : true;

        return matchesInactive && matchesPremium && matchesSms && matchesUserLevel && matchesEmailOnlyNotifications;

      });
    },
  },


  mounted() {
  
   
  this.startonlineUsersCheck();
 
 
},

beforeUnmount() {
  // Limpia el intervalo al destruir el componente
  if (this.onlineUsersCheckInterval) {
    clearInterval(this.onlineUsersCheckInterval);
  }



},

watch: {
    'subscriptionData.isActive': function (newValue) {
      if (!newValue) {
        this.removeSubscription(); // Elimina la suscripción si el checkbox es desmarcado
      }
    }
  },


  methods: {

    //Ventana Modal de Suscripciones de Usuarios

openSubscriptionModal(user) {
  this.subscriptionData = {
    userId: user.id,   
    name: user.name,
    duration: 7, // Valor por defecto
    isActive: user.is_premium || false
  };
  this.showModal = true;
},



    //Cierre de la Ventana Modal

    
    setActiveForm(form) {
      this.activeForm = form; // Cambia el formulario activo
      if (form === 'view') {
        this.fetchUsers();
      }     
    },

    //Ventana modal para notificaciones Push Directas a un usuarios

    openPushNotificationModal(user) {
      this.selectedPushUser = user;
      this.pushTitle = "";
      this.pushMessage = "";
      this.showPushModal = true;
    },
    closePushModal() {
      this.showPushModal = false;
    },
    async sendPushNotification() {
      if (!this.pushTitle || !this.pushMessage) {
        alert("El título y el mensaje son obligatorios.");
        return;
      }

      const token = localStorage.getItem('token');

      try {
        const response = await fetch("https://api.fortunia.org/api/sms/notify/user", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`, 
          },
          body: JSON.stringify({
            userId: this.selectedPushUser.id,
            title: this.pushTitle,
            body: this.pushMessage,
          }),
        });

        const result = await response.json();

        if (response.ok) {
          alert("Notificación enviada con éxito.");
          this.closePushModal();
        } else {
          alert("Error al enviar notificación: " + result.error);
        }
      } catch (error) {
        console.error("Error al enviar notificación:", error);
        alert("Error al enviar la notificación.");
      }
    },
  

    //Abrir la ventana modal para enviar SMS a un usaurio y cerrar la ventana 

    openSmsModal(user) {
      this.selectedUser = user;
      this.showSmsModal = true;
    },

    closeSmsModal() {
      this.showSmsModal = false;
      this.smsMessage = '';
    },


    async sendSms() {
      const token = localStorage.getItem('token');
      try {
        await axios.post('https://api.fortunia.org/api/sms/send-sms-user', {
          phoneNumber: this.selectedUser.phone_number,
          message: this.smsMessage
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        alert('SMS enviado con éxito');
        this.closeSmsModal();
      } catch (error) {
        console.error('Error al enviar SMS:', error);
        alert('Error al enviar SMS');
      }
    
  },

  //Fin de la funcion para el envio de SMS




  // Método para iniciar la verificación periódica
   startonlineUsersCheck() {
    // Limpia cualquier intervalo existente
     if (this.onlineUsersCheckInterval) {
          clearInterval(this.onlineUsersCheckInterval);
        }
  
        // Configura una verificación periódica cada 5 segundos (5000 ms)
        this.onlineUsersCheckInterval = setInterval(() => {
          this.fetchOnlineUsers();
        }, 5000);
      },
  


  async fetchUsers() {
  const token = localStorage.getItem('token');
  try {
    const response = await axios.get('https://api.fortunia.org/api/users/users', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Procesar los usuarios y determinar si son premium según la fecha de expiración
    this.users = response.data.map(user => {
      const currentDate = new Date();
      const premiumExpirationDate = new Date(user.premium_expiration_date);

      // Compara las fechas y asigna is_premium
      user.is_premium = premiumExpirationDate >= currentDate;

      return user;
    });
  } catch (error) {
    console.error('Error al obtener usuarios:', error);
    alert('Hubo un error al obtener los usuarios.');
  }
},



async fetchOnlineUsers() {
  const token = localStorage.getItem('token');
      try {
        const response = await axios.get(
          "https://api.fortunia.org/api/users/online-users",
          {
            headers: {
              Authorization: `Bearer ${token}`,    
            },
          }
        );
        this.onlineUsers = response.data;
      } catch (error) {
        console.error("Error al obtener usuarios en línea:", error);
        this.onlineUsers = [];
      }
    },
 


     async editUser(userId) {
     const user = this.users.find(u => u.id === userId);
       this.selectedUser = { ...user };
       // Asegúrate de que el valor de `receive_sms_alerts` sea un booleano
      this.selectedUser.receive_sms_alerts = Boolean(this.selectedUser.receive_sms_alerts);
      this.selectedUser.email_only_notifications = Boolean(this.selectedUser.email_only_notifications);

      this.setActiveForm('edit');
    },



//Actualizacion de datos de usuarios
  async updateUser() {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No hay un token disponible. Por favor, inicia sesión nuevamente.');
    return;
  }

  // Crear una copia de los datos del usuario
  const userData = { ...this.selectedUser };

  // Si el acceso premium es activado, asignar la fecha de vencimiento basada en la duración seleccionada
  if (userData.is_premium) {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.setDate(currentDate.getDate() + this.premiumDuration)); // Sumar los días seleccionados
    userData.premium_expiration_date = expirationDate.toISOString().split('T')[0]; // Formato YYYY-MM-DD
  } else {
    // Si no es premium, asegurarse de limpiar la fecha de vencimiento
    userData.premium_expiration_date = null;
  }

  // Eliminar la contraseña si no está presente
  if (!userData.password) {
    delete userData.password; // Elimina la contraseña si no se desea actualizar
  }

  try {
    // Primero, actualiza los datos del usuario
    await axios.put(
      `https://api.fortunia.org/api/update-users/update/${this.selectedUser.id}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    alert('Usuario actualizado con éxito!');

   
    this.selectedUser = {}; // Limpiar los datos del usuario seleccionado
    this.activeForm = null; // Cerrar el formulario

  } catch (error) {
    console.error('Error actualizando usuario:', error);
    if (error.response) {
      alert(`Error: ${error.response.data.message || 'No se pudo actualizar el usuario.'}`);
    } else {
      alert('Hubo un error al actualizar el usuario. Revisa tu conexión.');
    }
  }
},


async manageSubscription() {
  const token = localStorage.getItem('token');
  if (!token) {
    alert('No hay un token disponible. Por favor, inicia sesión nuevamente.');
    return;
  }

  // Validar si hay un usuario y un periodo de suscripción
  if (!this.subscriptionData.userId || !this.subscriptionData.duration) {
    alert('Faltan los datos necesarios para la suscripción.');
    return;
  }

  console.log('ID de usuario:', this.subscriptionData.userId);
  console.log('Duración:', this.subscriptionData.duration);

  // Asegurarse de que duration sea un número
  const duration = parseInt(this.subscriptionData.duration, 10);
  if (![7, 15, 30, 365].includes(duration)) {
    alert('El período debe ser 7, 15, 30 días o 1 año');
    return;
  }

  try {
    // Hacer la solicitud POST al endpoint para asignar la suscripción premium
    const response = await axios.post(
      `https://api.fortunia.org/api/update-users/assign-premium/${this.subscriptionData.userId}`,
      {
        period: duration, // Asegurarse de enviar el periodo como un número
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    // Verifica la respuesta antes de mostrarla
    console.log('Respuesta del servidor:', response.data);

    this.fetchUsers();
    // Si la respuesta es exitosa, mostrar mensaje y limpiar los campos
    alert(response.data.message || 'Suscripción premium asignada correctamente.');
    this.subscriptionData = { userId: null, duration: 7, isActive: false }; // Limpiar campos
    this.showModal = false;
  } catch (error) {
    console.error('Error asignando suscripción:', error.response?.data || error);
    alert(`Hubo un error al asignar la suscripción: ${error.response?.data?.message || error.message}`);
  }
},



async removeSubscription() {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`https://api.fortunia.org/api/update-users/remove-premium/${this.subscriptionData.userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        this.fetchUsers();
        alert('Suscripción eliminada correctamente.');
        this.subscriptionData.isActive = false; // Actualiza el estado de la UI
        this.showModal = false;
      }
    } catch (error) {
      console.error('Error al eliminar la suscripción:', error);
      alert('Hubo un error al eliminar la suscripción.');
    }
  },




// Método para asignar acceso premium
async assignPremiumAccess(userId, period) {
  const token = localStorage.getItem('token'); // Obtener el token de autenticación

  // Verificar que el periodo sea válido antes de hacer la solicitud
  if (![7, 15, 30].includes(period)) {
    alert('La duración del acceso premium debe ser 7, 15 o 30 días.');
    return;
  }

  try {
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + period); // Establecer fecha de vencimiento

    // Realizar la solicitud para asignar el acceso premium
    await axios.put(
      `https://api.fortunia.org/api/update-users/assign-premium/${userId}`,
      {
        premium_expiration_date: expirationDate.toISOString().split('T')[0], // Fecha en formato YYYY-MM-DD
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    alert('Acceso Premium asignado correctamente.');
  } catch (error) {
    console.error('Error asignando acceso premium:', error);
    alert('Hubo un error al asignar el acceso premium.');
  }
},




    async createUser() {
      this.isSubmitting = true;
      const token = localStorage.getItem('token');
      try {
        await axios.post(
          'https://api.fortunia.org/api/users',
          {
            ...this.newUser,
            receive_sms_alerts: this.newUser.receive_sms_alerts ? 1 : 0, // Convertir checkbox a 0 o 1
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Usuario creado con éxito!');
        this.resetCreateUserForm();
      } catch (error) {
        console.error('Error creando usuario:', error);
        alert('Hubo un error al crear el usuario. Por favor, intenta de nuevo.');
      } finally {
        this.isSubmitting = false;
      }
    },
    resetCreateUserForm() {
      this.newUser = {
        name: '',
        email: '',
        password: '',
        role: 'user',
        phone_number: '',
        receive_sms_alerts: false,
      };
      this.activeForm = null;
    },
  
    async deleteUser() {
      const token = localStorage.getItem('token');
      try {
        await axios.delete(
          `https://api.fortunia.org/api/update-users/delete/${this.deleteUserId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Usuario eliminado con éxito!');
        this.deleteUserId = null;
      } catch (error) {
        console.error('Error eliminando usuario:', error);
        alert('Hubo un error al eliminar el usuario. Por favor, intenta de nuevo.');
      }
    },

//Funcion para enviar nuevamente el correo de verificacion de un usuario 
  async resendVerificationEmail() {
  const token = localStorage.getItem('token');
  

  try {
    await axios.post(
      `https://api.fortunia.org/api/auth/resend-verification-email/${this.verificationUserId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    alert('Correo de verificación reenviado.');
    this.verificationUserId = null; // Reinicia el ID después de enviar
  } catch (error) {
    console.error('Error reenviando correo de verificación:', error);
    alert('Hubo un error al reenviar el correo.');
  }
},



    async changePassword() {
      const token = localStorage.getItem('token');
      try {
        await axios.patch(
          `https://api.fortunia.org/api/users/${this.changePasswordId}/password`,
          { password: this.newPassword },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        alert('Contraseña cambiada con éxito!');
        this.changePasswordId = null;
        this.newPassword = '';
      } catch (error) {
        console.error('Error cambiando la contraseña:', error);
        alert('Hubo un error al cambiar la contraseña. Por favor, intenta de nuevo.');
      }
    },


    async toggleUserStatus(userId) {
    const token = localStorage.getItem('token');
    const userToUpdate = this.users.find(user => user.id === userId);
    
    // Cambiar el estado de activación (si está activo, lo desactivamos, y viceversa)
    const newStatus = userToUpdate.is_verified ? 0 : 1;

    try {
      // Usamos el endpoint de actualización del usuario solo para modificar el estado
      await axios.patch(
        `https://api.fortunia.org/api/update-users/update/${userId}`,  
        { is_verified: newStatus }, // Solo enviamos el nuevo estado
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );    
      
      // Actualizar la lista de usuarios después de modificar el estado
      userToUpdate.is_verified = newStatus;
      alert(`Usuario ${newStatus ? 'Activado' : 'Desactivado'} con éxito!`);
    } catch (error) {
      console.error('Error cambiando el estado del usuario:', error);
      alert('Hubo un error al cambiar el estado del usuario.');
    }
  },

 
  
  },
};

</script>


<style scoped>
.user-management {
  font-family: 'Roboto', sans-serif;
  padding: 20px;
  max-width: 1200px;
  margin: auto;
}

h2 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

h3 {
  font-size: 1.5rem;
  color: #444;
  margin-bottom: 10px;
}

.admin-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.admin-menu button {
  padding: 10px 15px;
  font-size: 1rem;
  background: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.admin-menu button:hover {
  background: #0056b3;
}

form {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

form div {
  margin-bottom: 15px;
}

form label {
  display: block;
  font-size: 0.9rem;
  margin-bottom: 5px;
  color: #555;
}

form input, form select {
  width: 100%;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

form button {
  background: #28a745;
  color: #fff;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

form button:hover {
  background: #218838;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  text-align: left;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

table th {
  background: #f1f1f1;
  color: #333;
}

table tr:nth-child(even) {
  background: #fafafa;
}

@media (max-width: 768px) {
  .admin-menu {
    flex-direction: column;
    align-items: stretch;
  }

  form input, form select {
    font-size: 0.9rem;
  }

  h2, h3 {
    font-size: 1.2rem;
  }
}



/* Usuarios Activos en tiempo Real */

.online-users-container {
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  color: #343a40;
  margin-bottom: 15px;
  text-align: center;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.user-item {
  background-color: #ffffff;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.user-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-username {
  font-size: 18px;
  font-weight: 600;
  color: #007bff;
}

.last-active {
  font-size: 14px;
  color: #6c757d;
}

@media (max-width: 768px) {
  .online-users-container {
    padding: 15px;
  }

  .section-title {
    font-size: 20px;
  }

  .user-item {
    padding: 12px;
  }

  .user-username {
    font-size: 16px;
  }

  .last-active {
    font-size: 12px;
  }
}


/* Para el envio de SMS a un usuario */


.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  width: 90%;
  max-width: 420px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

.modal-content h3 {
  font-size: 20px;
  margin-bottom: 12px;
  color: #333;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 16px;
  resize: none;
  outline: none;
  transition: border-color 0.3s;
}

textarea:focus {
  border-color: #007bff;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-top: 15px;
}

.modal-actions button {
  flex: 1;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease-in-out, transform 0.2s ease;
}

.modal-actions button:first-child {
  background: #007bff;
  color: white;
}

.modal-actions button:first-child:hover {
  background: #0056b3;
}

.modal-actions button:last-child {
  background: #ccc;
  color: black;
}

.modal-actions button:last-child:hover {
  background: #999;
}

.modal-actions button:active {
  transform: scale(0.95);
}

/* Animación de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


/* Botones de los usuarios */

td button {
  padding: 10px 14px;
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin: 4px;
  min-width: 100px;
}

/* Botón Editar */
td button:first-child {
  background: #007bff;
  color: white;
}

td button:first-child:hover {
  background: #0056b3;
}

/* Botón Activar/Desactivar */
td button:nth-child(2) {
  background: #ff9800;
  color: white;
}

td button:nth-child(2):hover {
  background: #e68900;
}

/* Botón Enviar SMS */
td button:last-child {
  background: #28a745;
  color: white;
}

td button:last-child:hover {
  background: #1e7e34;
}

/* Efecto al presionar */
td button:active {
  transform: scale(0.95);
}


/* Estilo ventana Modal de las suscripciones Premium */
/* Fondo oscuro semitransparente */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  padding: 20px; /* Evita que la modal toque los bordes en pantallas pequeñas */
}

/* Caja modal principal */
.modal {
  background: white;
  width: 100%;
  max-width: 450px;
  min-width: 320px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
  animation: fadeIn 0.3s ease-in-out;
}

/* Título */
.modal h3 {
  margin-bottom: 15px;
  font-size: 22px;
  color: #333;
}

/* Campos de formulario */
.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  font-size: 14px;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #555;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  background: #f9f9f9;
}

/* Estilo para el toggle (checkbox) */
.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Botones */
.button-group {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.btn {
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  font-weight: bold;
}

/* Colores de los botones */
.primary {
  background: #007bff;
  color: white;
}

.primary:hover {
  background: #0056b3;
}

.secondary {
  background: #6c757d;
  color: white;
}

.secondary:hover {
  background: #545b62;
}

.danger {
  background: #dc3545;
  color: white;
}

.danger:hover {
  background: #b02a37;
}

/* Animación de entrada */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsivo */
@media (max-width: 480px) {
  .modal {
    max-width: 95%;
    padding: 18px;
  }

  .btn {
    font-size: 14px;
    padding: 10px;
  }
}



</style>