import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// Configuración de Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBQTA_4wXoRSn5SAdwYgWuP09_s2ycQOWE",
  authDomain: "fortunia-20170.firebaseapp.com",
  projectId: "fortunia-20170",
  storageBucket: "fortunia-20170.firebasestorage.app",
  messagingSenderId: "804993153246",
  appId: "1:804993153246:web:7d355cc90c17ff55f3aa4f",
  measurementId: "G-5Q3D7Q9E3Q"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// Registrar el Service Worker
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registrado correctamente:", registration);
    })
    .catch((error) => {
      console.error("Error al registrar el Service Worker:", error);
    });
}

// Solicitar permiso de notificaciones y obtener el token
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: "BH4Wm3qFe3UGA8te8QymrNEIDSO6uHe9znHnI7V5Kv6F-wM0VltzsmLHCUAvuJXiXMgYiuTq0fi5xHXwy-6Rn54"
      });
      console.log("Token de FCM obtenido: ", token);
      return token;
    } else {
      console.error("Permiso de notificación denegado.");
      return null;
    }
  } catch (error) {
    console.error("Error al obtener el token de FCM: ", error);
    return null;
  }
};

// Manejar mensajes recibidos en primer plano
onMessage(messaging, (payload) => {
  console.log("📩 Mensaje recibido en primer plano:", payload);

  if (Notification.permission === "granted") {
    const notificationOptions = {
      body: payload.notification.body,
      icon: "/favicon-96x96.png",
      data: { 
        url: payload.data?.click_action || "https://www.fortunia.org" // URL destino
      }
    };

    const notification = new Notification(payload.notification.title, notificationOptions);

    // Redirigir cuando el usuario haga clic en la notificación
    notification.onclick = (event) => {
      event.preventDefault();
      window.open(notificationOptions.data.url, "_blank");
    };

  } else {
    console.warn("⚠️ Permiso de notificación no concedido.");
  }
});

export default messaging;
