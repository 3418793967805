<template>
    <div class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div
        class="bg-white p-8 rounded-2xl shadow-lg w-full max-w-lg sm:max-w-md transform transition-all duration-300 scale-95 hover:scale-100"
      >
        <h2 class="text-3xl font-semibold text-gray-800 mb-8 text-center">
          {{ isEdit ? "Editar Clase" : "Nueva Clase" }}
        </h2>
  
        <form @submit.prevent="saveClass" class="space-y-6">
          <div>
            <label class="block text-gray-700 font-medium mb-2">Título:</label>
            <input
              v-model="localClassData.title"
              type="text"
              class="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 shadow-md text-lg"
              required
            />
          </div>
  
          <div>
            <label class="block text-gray-700 font-medium mb-2">Fecha:</label>
            <VueDatePicker
              v-model="localClassData.class_date"
              class="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 shadow-md text-lg"
              required
            />
          </div>
  
          <div>
            <label class="block text-gray-700 font-medium mb-2">Hora:</label>
            <input
              v-model="localClassData.class_time"
              type="time"
              class="w-full p-4 border rounded-lg focus:ring-2 focus:ring-blue-500 shadow-md text-lg"
              required
            />
          </div>
  
          <div>
            <label class="block text-gray-700 font-medium mb-2">Nivel:</label>
            <select
              v-model="localClassData.level"
              class="w-full p-4 border rounded-lg bg-white focus:ring-2 focus:ring-blue-500 shadow-md text-lg"
            >
              <option value="Básico">Básico</option>
              <option value="Intermedio">Intermedio</option>
              <option value="Avanzado">Avanzado</option>
              <option value="Todos">Todos</option>
            </select>
          </div>
  
          <div class="flex justify-between items-center mt-6">
            <button
              type="button"
              @click="$emit('close')"
              class="px-6 py-2 rounded-lg bg-gray-600 text-white hover:bg-gray-700 transition duration-300 ease-in-out"
            >
              Cancelar
            </button>
            <button
              type="submit"
              class="px-6 py-2 rounded-lg bg-blue-600 text-white hover:bg-blue-700 transition duration-300 ease-in-out"
            >
              Guardar
            </button>
          </div>
        </form>
      </div>
    </div>
  </template>
  
  
  <script>
  import VueDatePicker from "@vuepic/vue-datepicker";
  import "@vuepic/vue-datepicker/dist/main.css";
  import axios from "axios";
  
  export default {
    components: {
      VueDatePicker,
    },
    props: ["classData"],
    data() {
      return {
        localClassData: { ...this.classData },
      };
    },
    watch: {
      classData: {
        handler(newVal) {
          this.localClassData = { ...newVal };
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      async saveClass() {
        try {
          if (this.isEdit) {
            await axios.put(
              `https://api.fortunia.org/api/manageclases/classes/${this.localClassData.id}`,
              this.localClassData,
              { headers: this.getAuthHeaders() }
            );
          } else {
            await axios.post(
              "https://api.fortunia.org/api/manageclases/classes",
              this.localClassData,
              { headers: this.getAuthHeaders() }
            );
          }
          this.$emit("saved");
          this.$emit("close");
        } catch (error) {
          console.error("Error al guardar la clase:", error);
        }
      },
    },
  };
  </script>
  
  <style scoped>
/* Animaciones */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease-in-out;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

/* Estilos responsivos */
@media (max-width: 640px) {
  .max-w-lg {
    max-width: 100%;
  }
  .text-lg {
    font-size: 1rem;
  }
  .p-4 {
    padding: 16px;
  }
}

@media (min-width: 640px) {
  .sm:max-w-md {
    max-width: 100%;
  }
}

/* Estilos generales */
.bg-white {
  background-color: #fff;
}
.text-gray-800 {
  color: #2d3748;
}
.text-gray-700 {
  color: #4a5568;
}
.bg-gray-600 {
  background-color: #4a5568;
}
.bg-blue-600 {
  background-color: #3182ce;
}
.bg-blue-700 {
  background-color: #2b6cb0;
}
.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}
.text-center {
  text-align: center;
}
.text-lg {
  font-size: 1.125rem;
}
.text-2xl {
  font-size: 1.5rem;
}
.text-3xl {
  font-size: 1.875rem;
}
.font-semibold {
  font-weight: 600;
}
.font-medium {
  font-weight: 500;
}
.p-8 {
  padding: 32px;
}
.p-4 {
  padding: 16px;
}
.rounded-2xl {
  border-radius: 1rem;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.w-full {
  width: 100%;
}
.space-y-6 {
  gap: 1.5rem;
}
.space-y-8 {
  gap: 2rem;
}
</style>