<template>
    <div class="library-container">
      <h1 class="title">Biblioteca Recomendada</h1>
      <p class="description">
        Comprar un libro no solo te brinda la experiencia de leer y aprender, sino que también apoya al autor y reconoce su labor.
      </p>

      <button @click="$router.go(-1)" class="back-button">← Volver atrás</button>

      <div class="books-grid">
        <div v-for="book in books" :key="book.id" class="book-card">
          <img :src="book.cover_image" :alt="book.title" class="book-cover" />
          <h2 class="book-title">{{ book.title }}</h2>
          <p class="book-description">{{ book.description }}</p>
          <p class="book-price">Precio: ${{ book.price }}</p>
          <div class="book-links">
            <a :href="book.amazon_link" target="_blank" class="amazon-link">📖 Comprar en Amazon</a>
            <a v-if="book.youtube_link" :href="book.youtube_link" target="_blank" class="youtube-link">🎧 Escuchar Gratis</a>
          </div>
        </div>
      </div>



     <!-- Pie de página -->
     <footer class="footer">
      <p>Fortunia 2024 Todos los Derechos Reservados</p>
      <p>Creado por <strong>Coss Consulting Group</strong></p>
      <nav>
        <ul>
          <li><router-link to="/privacy">Política de Privacidad</router-link></li>
          <li><router-link to="/disclaimer">Disclaimers</router-link></li>
          <li><router-link to="/about">Acerca de</router-link></li>
          <li><a href="https://blog.fortunia.org" target="_blank" rel="noopener noreferrer">Blog</a></li>
  
        </ul>
      </nav>
    </footer>


    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        books: [],
      };
    },
    async created() {
  try {
    const response = await axios.get("https://api.fortunia.org/api/mindfulness/books", {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    });
    this.books = response.data;
  } catch (error) {
    console.error("❌ Error al obtener los libros:", error);
  }
}

  };
  </script>
  
  <style scoped>
  .library-container {
    max-width: 900px;
    margin: auto;
    padding: 20px;
    text-align: center;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .description {
    margin-bottom: 20px;
    font-size: 1rem;
    color: gray;
  }
  
  .books-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .book-card {
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .book-cover {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  .book-title {
    font-size: 1.2rem;
    margin: 10px 0;
  }
  
  .book-description {
    font-size: 0.9rem;
    color: #666;
  }
  
  .book-price {
    font-weight: bold;
    margin: 10px 0;
  }
  
  .book-links a {
    display: block;
    margin: 5px 0;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
  }
  
  .amazon-link {
    background: #FF9900;
    color: white;
  }
  
  .youtube-link {
    background: #FF0000;
    color: white;
  }



/* Pie de Página */
.footer {
  margin-top: 20px;
  padding: 15px;
  background-color: var(--secondary-color);
  text-align: center;
  border-top: 1px solid #ddd;
  font-size: 14px;
  color: #030303;
}

.footer p {
  margin: 5px 0;
}

.footer nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
  flex-wrap: wrap;
}

.footer nav ul li {
  margin: 5px 0;
}

.footer nav ul li a {
  text-decoration: none;
  color: var(--primary-color);
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 14px;
}

.footer nav ul li a:hover {
  color: #000;
}

/* Estilos específicos para pantallas pequeñas */
@media (max-width: 768px) {
  .footer {
    font-size: 12px;
    padding: 10px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 10px;
  }

  .footer nav ul li a {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer {
    font-size: 10px;
    padding: 8px;
  }

  .footer nav ul {
    flex-direction: column;
    gap: 5px;
  }

  .footer nav ul li a {
    font-size: 10px;
  }
}

/* Estilo adicional para mejorar la visualización */
.footer p strong {
  font-weight: bold;
}


/* Este es el boton de Atras */

.back-button {
  background-color: #4CAF50; /* Color verde */
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
  background-color: #45a049; /* Verde más oscuro al pasar el ratón */
  transform: translateY(-2px); /* Efecto de elevación */
}

.back-button:focus {
  outline: none;
}

.back-button svg {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

@media (max-width: 768px) {
  .back-button {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .back-button {
    font-size: 0.8rem;
    padding: 8px 14px;
  }
}

  </style>
  