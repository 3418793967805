<template>
    <div class="manage-books">
      <h1>📚 Gestión de Libros</h1>

      <button @click="$router.go(-1)" class="back-button">← Volver atrás</button>
  
      <!-- Filtros -->
      <div class="filters">
        <input v-model="searchQuery" placeholder="🔍 Buscar por título o descripción" />
        <input v-model.number="minPrice" type="number" placeholder="💰 Precio mínimo" />
        <input v-model.number="maxPrice" type="number" placeholder="💰 Precio máximo" />
      </div>
  
      <!-- Formulario para agregar o editar libros -->
      <form @submit.prevent="editing ? updateBook() : addBook()">
        <h2>{{ editing ? '✏️ Editar Libro' : '➕ Agregar Nuevo Libro' }}</h2>
        <input v-model="book.title" placeholder="Título" required />
        <textarea v-model="book.description" placeholder="Descripción" required></textarea>
        <input v-model="book.price" type="number" placeholder="Precio" required />
        <input v-model="book.cover_image" placeholder="URL de la portada" required />
        <input v-model="book.amazon_link" placeholder="Enlace de Amazon" required />
        <input v-model="book.youtube_link" placeholder="Enlace a Audiolibro (opcional)" />
        <button type="submit">{{ editing ? 'Actualizar' : 'Agregar' }}</button>
        <button v-if="editing" @click="cancelEdit" type="button">Cancelar</button>
      </form>
  
      <!-- Lista de libros con paginación -->
      <h2>📖 Libros en la Base de Datos</h2>
      <div class="books-list">
        <div v-for="b in paginatedBooks" :key="b.id" class="book-item">
          <img :src="b.cover_image" :alt="b.title" />
          <div class="book-details">
            <h3>{{ b.title }}</h3>
            <p>{{ b.description }}</p>
            <p><strong>Precio:</strong> ${{ b.price }}</p>
            <a :href="b.amazon_link" target="_blank">🛒 Comprar</a>
            <a v-if="b.youtube_link" :href="b.youtube_link" target="_blank">🎧 Escuchar Gratis</a>
            <div class="actions">
              <button @click="editBook(b)">✏️ Editar</button>
              <button @click="deleteBook(b.id)">🗑️ Eliminar</button>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Paginación -->
      <div class="pagination">
        <button @click="prevPage" :disabled="currentPage === 1">⬅️ Anterior</button>
        <span>Página {{ currentPage }} de {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">➡️ Siguiente</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        books: [],
        book: {
          id: null,
          title: '',
          description: '',
          price: '',
          cover_image: '',
          amazon_link: '',
          youtube_link: '',
        },
        searchQuery: '',
        minPrice: null,
        maxPrice: null,
        editing: false,
        currentPage: 1,
        booksPerPage: 5,
      };
    },
    async created() {
      this.fetchBooks();
    },
    computed: {
      filteredBooks() {
        return this.books.filter((b) => {
          const matchesSearch =
            b.title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            b.description.toLowerCase().includes(this.searchQuery.toLowerCase());
          const matchesPrice =
            (!this.minPrice || b.price >= this.minPrice) &&
            (!this.maxPrice || b.price <= this.maxPrice);
          return matchesSearch && matchesPrice;
        });
      },
      paginatedBooks() {
        const start = (this.currentPage - 1) * this.booksPerPage;
        return this.filteredBooks.slice(start, start + this.booksPerPage);
      },
      totalPages() {
        return Math.ceil(this.filteredBooks.length / this.booksPerPage);
      },
    },
    methods: {
  getAuthHeaders() {
    return { Authorization: `Bearer ${localStorage.getItem("token")}` };
  },

  async fetchBooks() {
    try {
      const response = await axios.get("https://api.fortunia.org/api/mindfulness/books", {
        headers: this.getAuthHeaders(),
      });
      this.books = response.data;
    } catch (error) {
      console.error("❌ Error al obtener libros:", error);
    }
  },

  async addBook() {
    try {
      await axios.post("https://api.fortunia.org/api/mindfulness/books", this.book, {
        headers: this.getAuthHeaders(),
      });
      this.fetchBooks();
      this.resetForm();
    } catch (error) {
      console.error("❌ Error al agregar libro:", error);
    }
  },

  editBook(book) {
    this.book = { ...book };
    this.editing = true;
  },

  async updateBook() {
    try {
      await axios.put(`https://api.fortunia.org/api/mindfulness/books/${this.book.id}`, this.book, {
        headers: this.getAuthHeaders(),
      });
      this.fetchBooks();
      this.resetForm();
    } catch (error) {
      console.error("❌ Error al actualizar libro:", error);
    }
  },

  async deleteBook(id) {
    if (confirm("¿Estás seguro de que deseas eliminar este libro?")) {
      try {
        await axios.delete(`https://api.fortunia.org/api/mindfulness/books/${id}`, {
          headers: this.getAuthHeaders(),
        });
        this.fetchBooks();
      } catch (error) {
        console.error("❌ Error al eliminar libro:", error);
      }
    }
  },


      resetForm() {
        this.book = { id: null, title: '', description: '', price: '', cover_image: '', amazon_link: '', youtube_link: '' };
        this.editing = false;
      },
      cancelEdit() {
        this.resetForm();
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .manage-books {
    max-width: 800px;
    margin: auto;
    text-align: center;
  }
  
  .filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .filters input {
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
  }
  
  .books-list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .book-item {
    background: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .actions button {
    margin: 5px;
    padding: 5px;
    border: none;
    cursor: pointer;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }




/* Este es el boton de Atras */

.back-button {
  background-color: #4CAF50; /* Color verde */
  color: white;
  font-size: 1rem;
  padding: 12px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.back-button:hover {
  background-color: #45a049; /* Verde más oscuro al pasar el ratón */
  transform: translateY(-2px); /* Efecto de elevación */
}

.back-button:focus {
  outline: none;
}

.back-button svg {
  margin-right: 8px; /* Espacio entre el ícono y el texto */
}

@media (max-width: 768px) {
  .back-button {
    font-size: 0.9rem;
    padding: 10px 16px;
  }
}

@media (max-width: 480px) {
  .back-button {
    font-size: 0.8rem;
    padding: 8px 14px;
  }
}

  </style>
  